@import 'app/common/styles/background-colors.module.scss';
@import 'app/common/styles/status-colors.module.scss';
@import 'app/common/styles/icon-colors.module.scss';
@import 'app/common/styles/breakpoints.module.scss';

.closeButton {
  position: absolute;
  transition: fill 0.3s;
  z-index: 1;
  cursor: pointer;
  &:hover {
    fill: $status-danger;
  }
  &:active {
    fill: $icon-primary;
  }
}

.closeButtonMobile {
  opacity: 0;
  inset-block-start: 1.625rem;
  inset-inline-end: 1rem;
  transition: opacity 0.2s;
  &.active {
    opacity: 1;
  }
}

.closeButtonDesktop {
  display: none;
}

.drawerLayout {
  width: 100%;
  position: relative;
  padding-block: 1.5rem;
  padding-inline: 1rem;
  display: flex;
  flex-direction: column;
  height: auto;

  .title {
    margin-inline-end: 2rem;
    white-space: break-spaces;
  }
  .contentWrapper {
    width: 100%;
    padding-block-start: 2rem;
    margin: 0 auto;
    overflow-y: hidden;
    flex: 1;
  }
}

.left, [dir='rtl'] .right {
  animation: hideLeft 0.3s ease-out forwards;
  will-change: animation;
  cursor: default;
  &.active {
    animation: showLeft 0.3s ease-out forwards;
  }
}
.right, [dir='rtl'] .left {
  animation: hideRight 0.3s ease-out forwards;
  will-change: animation;
  cursor: default;
  &.active {
    animation: showRight 0.3s ease-out forwards;
  }
}

@include lg-breakpoint {
  .drawerLayout {
    max-width: 100%;
    border: 0.0625rem solid rgba(0, 0, 0, 0.08);
    background-color: $bg-island;
    box-shadow: $bg-island-shadow;
    border-radius: 1rem;
    padding-block: 1.5rem;
    padding-inline: 1rem;
    &.small {
      width: 20rem;
    }
    &.medium {
      width: 37.5rem;
    }
    .closeButtonDesktop {
      display: block;
      inset-block-start: 1.625rem;
      inset-inline-end: 1rem;
    }
  }
  .closeButtonMobile {
    display: none;
  }
}

@keyframes showLeft {
  from {
    opacity: 0;
    transform: translateX(-125%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes hideLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-125%);
  }
}
@keyframes showRight {
  from {
    opacity: 0;
    transform: translateX(125%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes hideRight {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(125%);
  }
}
