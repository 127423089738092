.defaultLogo {
  flex-grow: 0;
  flex-shrink: 0;

  &:focus {
    outline: none;
  }

  &.medium {
    width: 7.5rem;
    height: 2.5rem;
  }
  &.small {
    width: 6rem;
    height: 2rem;
  }
}

.customlogo {
  object-fit: contain;

  &Wrapper {
    display: flex;

    &.medium {
      max-width: 7.5rem;
      max-height: 2.5rem;
    }
    &.small {
      max-width: 6rem;
      max-height: 2rem;
    }
  }
}
