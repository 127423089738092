@import 'app/common/styles/background-colors.module.scss';
@import 'app/common/styles/text-colors.module.scss';
@import 'app/common/styles/text-sizes.module.scss';
@import 'app/common/styles/status-colors.module.scss';
@import 'app/common/styles/icon-colors.module.scss';
@import 'app/common/styles/breakpoints.module.scss';

.root {
  z-index: 1000;
  position: fixed;

  &.topCenter {
    inset-block-start: 1rem;
    inset-inline-start: 50%;
    transform: translateX(-50%);
  }

  &.bottomRight {
    inset-block-end: 1rem;
    inset-inline-end: 1rem;
  }

  @include xl-breakpoint {
    &.topCenter {
      inset-block-start: 2rem;
    }
    &.bottomRight {
      inset-block-end: 2rem;
      inset-inline-end: 2rem;
    }
  }
}

.notification {
  position: relative;
  overflow: hidden;
  width: 20.5rem;
  max-width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  border-radius: 0.5rem;
  box-shadow: $bg-island-shadow;
  background-color: #fff;
  margin-block-end: 0.5rem;
  opacity: 0;
  cursor: default;

  &.info {
    color: inherit;
  }

  &.success,
  &.error {
    color: $text-primary-inverse;

    .icon {
      fill: $icon-primary-inverse;
    }
  }

  &.success {
    background-color: $status-success;
  }

  &.error {
    background-color: $status-danger;
  }

  &.bottomRight {
    animation: fromRightToHide 1 ease-in-out forwards;
  }

  &.topCenter {
    animation: fromTopToHide 1 ease-in-out forwards;
  }

  &:hover {
    &.topCenter,
    &.bottomRight {
      animation-play-state: paused;
    }
  }

  .icon {
    fill: $status-danger;
  }

  .iconWrapper {
    padding-inline: 1rem 0;
    padding-block: 1rem;
  }

  .content {
    padding: 1rem 0;
    padding-inline-end: 1.5rem;
    padding-inline-start: 0.5rem;
  }

  .title {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: $t-weight-bold;
    padding-block-end: 0.5rem;
  }

  .message {
    font-size: 0.875rem;
  }
}

@keyframes fromRightToHide {
  from {
    opacity: 0.2;
    max-height: 0;
  }
  5% {
    opacity: 1;
  }
  10% {
    opacity: 1;
    max-height: 187.5rem;
  }
  90% {
    opacity: 1;
    max-height: 187.5rem;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    max-height: 0;
    transform-origin: 50% 100%;
  }
}

@keyframes fromTopToHide {
  from {
    opacity: 0.2;
    max-height: 0;
    transform: translateY(-101vh);
  }
  5% {
    opacity: 1;
    transform: translateY(0);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
    max-height: 187.5rem;
  }
  90% {
    opacity: 1;
    max-height: 187.5rem;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    max-height: 0;
    transform-origin: 50% 0;
  }
}
