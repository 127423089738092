@import 'app/common/styles/button-colors.module.scss';

.popupSettingsListItem {
  padding: 0.5rem;
  user-select: none;
  cursor: pointer;

  svg {
    transition: fill 0.3s;
  }

  &:hover,
  &:focus {
    color: $button-outline-border-text-hover;

    svg {
      fill: $button-outline-border-text-hover;
    }
  }

  &:active,
  &.active {
    color: $button-outline-border-text-active;

    svg {
      fill: $button-outline-border-text-active;
    }
  }

  &:not(:last-of-type) {
    margin-block-end: 0.25rem;
  }

  .icon {
    margin-inline-end: 0.5rem;
  }

  .text {
    white-space: nowrap;
    transition: color 0.3s;
  }
}
