@import 'app/common/styles/background-colors.module.scss';

.modalMenuOverlay {
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 100vw;
  min-width: 22.5rem;
  height: calc(100% - 3.5rem);
  background-color: $bg-island;
  z-index: 105;
  will-change: animation;
  opacity: 0;
  transform: translateY(101vh);
  transition: opacity 0.3s, transform 0.3s;

  &.active {
    opacity: 1;
    transform: translateY(0);
  }
}
