@import 'app/common/styles/_breakpoints.module.scss';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline: 1rem 0.75rem;
  padding-block: 2.5rem;
  min-width: 22.5rem;
  min-height: 100vh;

  .contentWrapper {
    min-height: 25rem;
    margin-block-end: 3rem;

    @include xl-breakpoint {
      margin-block-end: 5rem;
    }
  }
}
