@import 'app/common/styles/background-colors.module.scss';

.optionsListWrapper {
  position: absolute;
  inset-inline-start: 0;
  inset-block-end: -0.25rem;
  width: 100%;
  max-height: 22.75rem;
  overflow-y: auto;
  padding: 0 1rem;
  border-radius: 0.5rem;
  background-color: $bg-island;
  box-shadow: $bg-island-shadow;
  transform: translateY(100%);
  visibility: hidden;

  .footer {
    opacity: 0;
    position: sticky;
    inset-block-end: 0;
    padding: 1rem 0;
    background-color: $bg-island;
    border-top: 0.0625rem solid $bg-border;
    z-index: 1;
  }

  &.active {
    transition: all 0.3s;
    visibility: visible;
    z-index: 10;

    .footer {
      opacity: 1;
    }
  }

  .contentWrapper {
    position: relative;
  }
}
