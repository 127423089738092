//buttons primary
$button-primary-fill-border-default: var(--buttons-primary-fill-border-default);
$button-primary-fill-border-hover: var(--buttons-primary-fill-border-hover);
$button-primary-fill-border-active: var(--buttons-primary-fill-border-active);
$button-primary-fill-border-disabled: var(--buttons-primary-fill-border-disabled);
$button-primary-text: var(--buttons-primary-text);

//buttons inverse
$button-inverse-fill: var(--buttons-inverse-fill);
$button-inverse-text-default: var(--buttons-inverse-text-default);
$button-inverse-text-hover: var(--buttons-inverse-text-hover);
$button-inverse-fill-text-active: var(--buttons-inverse-text-active);
$button-inverse-fill-shadow-active: var(--buttons-inverse-shadow-active);
$button-inverse-fill-text-disabled: var(--buttons-inverse-text-disabled);
$button-inverse-shadow-hover: var(--buttons-inverse-shadow-hover);

//buttons outline
$button-outline-fill: var(--buttons-outline-fill);
$button-outline-border-text-default: var(--buttons-outline-border-text-default);
$button-outline-border-text-hover: var(--buttons-outline-border-text-hover);
$button-outline-border-text-active: var(--buttons-outline-border-text-active);
$button-outline-border-text-disabled: var(--buttons-outline-border-text-disabled); ;
