@import 'app/common/layouts/main-layout/variables';
@import 'app/common/styles/breakpoints.module.scss';

.root {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding-inline: 1rem 0.75rem;

  .content {
    align-self: stretch;
    position: relative;
    width: 75rem;
    min-width: 0;
    padding-block: 1rem 3rem;

    &.withMobileMenu {
      padding-block: 5rem 6rem;

      @include lg-breakpoint {
        padding-block: 1rem 3rem;
      }
    }

    @media screen and (max-width: $mediaMaxWidth) {
      width: 100%;
    }
  }
}
