@import 'app/common/styles/background-colors.module.scss';
@import 'app/common/styles/status-colors.module.scss';
@import 'app/common/styles/text-colors.module.scss';
@import 'app/common/styles/icon-colors.module.scss';

.baseIcon {
  white-space: nowrap;
  flex-grow: 0;
  flex-shrink: 0;

  &:focus {
    outline: none;
  }
}

.primary {
  fill: $icon-primary;
}

.primaryLight {
  fill: $bg-island-inner;
}

.text {
  fill: $text-primary;
}

.textLight {
  fill: $text-secondary;
}

.contrast {
  fill: $icon-primary-inverse;
}

.success {
  fill: $status-success;
}

.error {
  fill: $status-danger;
}

.warning {
  fill: $status-warning;
}

[dir="rtl"] .baseIcon {
  transform: scaleX(-1);
}

.withoutMirroring {
  transform: unset !important;
}
