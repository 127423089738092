@import 'app/common/styles/background-colors.module.scss';
@import 'app/common/styles/text-colors.module.scss';

.option {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-inline: 1rem 0.5rem;
  padding-block: 0.75rem;
  border-radius: 0.5rem;
  min-height: 2.75rem;
  cursor: pointer;

  .optionDesc {
    display: block;
    padding-block-start: 0.25rem;
    color: $text-secondary;
  }

  .optionLabel,
  .optionDesc {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    z-index: 1;
  }

  .icon {
    margin-inline-end: 0.5rem;
    flex-shrink: 0;
  }

  &:first-of-type {
    margin-block-start: 1rem;
  }

  &:last-of-type {
    margin-block-end: 1rem;
  }

  &:not(:first-of-type) {
    margin-block-start: 0.25rem;
  }

  &:hover {
    background-color: $bg-island-inner;
  }

  &.disabled {
    opacity: 0.5;
    background-color: transparent;
    cursor: not-allowed;
  }

  &.active {
    background-color: $bg-accent;
    color: $text-primary-inverse;

    .optionDesc {
      color: $text-secondary-inverse;
    }
  }

  &.empty {
    text-align: center;
  }
}

.emptyOptionsList {
  height: 2.75rem;
  margin: 0.5rem 0;
  padding-inline: 1rem 0.5rem;
  padding-block: 0.625rem;
  text-align: center;
  line-height: 1.5rem;
  cursor: default;
}
