.controllerWrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  font-size: 0.875rem;

  &[hidden] {
    display: none;
  }

  .label {
    display: flex;
    align-items: center;
    padding-block-end: 0.25rem;
    line-height: 1.25rem;
  }

  .helperText {
    padding-block-start: 0.375rem;
    line-height: 1rem;
  }
}
