@import 'app/common/styles/background-colors.module.scss';

.infoBlock {
  border-radius: 0.5rem;

  .infoWrapper {
    flex-basis: 100%;
    vertical-align: middle;
    line-height: 1.25rem;
    -moz-hyphens: none;
    hyphens: none;
    word-wrap: break-word;
  }

  .icon {
    align-self: flex-start;
  }

  &.fullWidth {
    width: 100%;
  }

  &.def {
    background-color: $bg-island-inner;
  }

  &.success {
    background-color: $bg-success-light;
  }

  &.warning {
    background-color: $bg-warning-light;
  }

  &.error {
    background-color: $bg-erorr-light;
  }
}
