@import 'app/common/styles/background-colors.module.scss';
@import 'app/common/styles/status-colors.module.scss';
@import 'app/common/styles/text-colors.module.scss';
@import 'app/common/styles/text-sizes.module.scss';

.textArea {
  $textArea: &;
  display: flex;
  width: 100%;
  max-height: 10rem;
  resize: none;
  padding: 0 1rem;
  box-shadow: none;
  border: none;
  background-color: transparent;
  outline: none !important;
  color: $text-primary;
  @include body-s;

  &::placeholder {
    color: $text-teritary;
  }

  &Wrapper {
    position: relative;
    width: 100%;
    padding-block-start: 0.875rem;
    padding-block-end: 1.5rem;
    background-color: $bg-island;
    border: 0.0625rem solid $bg-border;
    border-radius: 0.5rem;
    cursor: text;

    .additionalWrapper {
      position: absolute;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      user-select: none;
      inset-block-end: 0.875rem;
      inset-inline-end: 0.5rem;
      transform: translateY(50%);
    }

    &:focus-within {
      border-color: $bg-accent;
    }

    &.disabled {
      background-color: $bg-island-inner;
      cursor: not-allowed;
    }

    &.error {
      border-color: $status-danger;

      #{$textArea} {
        color: $status-danger;

        &::-webkit-input-placeholder {
          color: $status-danger;
        }

        &:-moz-placeholder {
          color: $status-danger;
        }

        &::-moz-placeholder {
          color: $status-danger;
        }

        &:-ms-input-placeholder {
          color: $status-danger;
        }

        &::placeholder {
          color: $status-danger;
        }
      }
    }
  }
}
