@import 'app/common/styles/background-colors.module.scss';
@import 'app/common/styles/text-colors.module.scss';
@import 'app/common/styles/text-sizes.module.scss';
@import 'app/common/styles/status-colors.module.scss';
@import 'app/common/styles/breakpoints.module.scss';

@mixin typographyMap($prefix: '') {
  &.#{$prefix}body-xs {
    @include body-xs;
  }
  &.#{$prefix}body-m {
    @include body-m;
  }
  &.#{$prefix}body-s {
    @include body-s;
  }
  &.#{$prefix}body-l {
    @include body-l;
  }
  &.#{$prefix}h0 {
    @include h0;
  }
  &.#{$prefix}h1 {
    @include h1;
  }
  &.#{$prefix}h2 {
    @include h2;
  }
  &.#{$prefix}h3 {
    @include h3;
  }
  &.#{$prefix}h4 {
    @include h4;
  }
  &.#{$prefix}h5 {
    @include h5;
  }
  &.#{$prefix}h6 {
    @include h6;
  }
  &.#{$prefix}subtitle1 {
    @include subtitle1;
  }
  &.#{$prefix}subtitle2 {
    @include subtitle2;
  }
}

@mixin alignMap($prefix: '') {
  &.#{$prefix}align {
    &-left {
      text-align: start;
    }

    &-right {
      text-align: end;
    }

    &-center {
      text-align: center;
    }
  }
}

@mixin allStylesMap($prefix: '') {
  @include typographyMap($prefix);
  @include alignMap($prefix);
}

.typography {
  white-space: pre-line;

  @include allStylesMap();

  &.color {
    &-primary {
      color: $text-primary;
    }

    &-light {
      color: $text-teritary;
    }

    &-medium-light {
      color: $text-secondary;
    }

    &-semi-light {
      color: $text-secondary;
    }

    &-white {
      color: $text-primary-inverse;
    }

    &-main {
      color: $text-link;
    }

    &-error {
      color: $status-danger;
    }

    &-success {
      color: $status-success;
    }

    &-warning {
      color: $status-warning;
    }
  }

  &.weight {
    &-normal {
      font-weight: $t-weight-normal;
    }

    &-medium-bold {
      font-weight: $t-weight-medium-bold;
    }

    &-semi-bold {
      font-weight: $t-weight-semi-bold;
    }

    &-bold {
      font-weight: $t-weight-bold;
    }

    &-extra-bold {
      font-weight: $t-weight-extra-bold;
    }
  }

  @include sm-breakpoint {
    @include allStylesMap('sm-');
  }

  @include md-breakpoint {
    @include allStylesMap('md-');
  }

  @include lg-breakpoint {
    @include allStylesMap('lg-');
  }

  @include xl-breakpoint {
    @include allStylesMap('xl-');
  }
}
