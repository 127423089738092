.drawer {
  padding-block: 1.5rem;
  padding-inline: 0;

  :global(.drawer-title) {
    padding-block: 0;
    padding-inline: 1rem;
  }

  :global(.drawer-content-wrapper) {
    padding-block-start: 0.5rem;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    flex: 1;
  }
}

