@import 'app/common/styles/background-colors.module.scss';
@import 'app/common/styles/text-colors.module.scss';
@import 'app/common/styles/breakpoints.module.scss';

.cardWrapper {
  padding-block: 0.625rem 0.5rem;
  padding-inline: 1rem;

  display: flex;
  flex-direction: column;
  padding-inline-end: calc(1rem - var(--drawer-scrollbar-width));

  .title {
    padding-inline-end: 2rem;
  }

  .date {
    margin-block-start: 0.375rem;
  }

  .readButton {
    padding-block: 0.125rem;
    padding-inline: 0.125rem;
    background-color: $bg-island;
    border-radius: 0.25rem;
  }

  .readButton {
    position: absolute;
    inset-inline-end: calc(1rem - var(--drawer-scrollbar-width));
    inset-block-start: 0.5rem;
  }
}

.link {
  .title {
    color: $text-link;
  }
  &:hover {
    cursor: pointer;
  }
  @include lg-breakpoint {
    .title {
      color: $text-primary;
    }
    &:hover {
      background-color: $bg-island-inner;
      .title {
        color: $text-link;
      }
    }
  }
}
