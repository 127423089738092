.tabsContent {
  position: relative;
  max-height: 0;
  &.hidden {
    margin: 0;
    padding: 0;
    opacity: 0;
    overflow: hidden;
  }
  &.active {
    opacity: 1;
    max-height: 1000rem;
    transition: opacity 0.5s;
    overflow: visible;
  }
}
