@import 'app/common/styles/breakpoints.module.scss';

.modalFooter {
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: 0;
  width: 100%;
  padding-block-start: 1rem;
  padding-inline: 1rem;
  flex-direction: column;

  button {
    width: 100%;
    @include sm-breakpoint {
      width: auto;
    }
  }

  @include sm-breakpoint {
    flex-direction: row;
  }

  @include xl-breakpoint {
    padding-block-start: 3rem;
    padding-inline: 0;
    position: relative;
  }
}
