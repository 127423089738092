.wallpaper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  z-index: 10000;
}
