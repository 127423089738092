@import 'app/common/styles/background-colors.module.scss';
@import 'app/common/styles/text-colors.module.scss';
@import 'app/common/styles/text-sizes.module.scss';

@mixin sizes($size) {
  width: #{$size};
  max-width: #{$size};
  height: #{$size};
  max-height: #{$size};
}

.userAvatar {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  font-weight: $t-weight-bold;
  color: $text-link;
  text-transform: uppercase;
  letter-spacing: 0.00625rem;
  flex-shrink: 0;
  flex-grow: 0;
  z-index: 0;

  &.border {
    outline: 0.0625rem solid;
    outline-color: $bg-accent;
    outline-offset: -0.0625rem;

    &.contrast {
      outline-color: $bg-island;
    }
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    pointer-events: none;
  }

  &::before {
    background-color: $bg-accent;
    z-index: -2;
  }

  &::after {
    background-color: #fff;
    opacity: 0.75;
    z-index: -1;
  }

  &.contrast {
    background-color: $bg-accent;
    color: $text-primary-inverse;

    &::before,
    &::after {
      display: none;
    }
  }

  &.small {
    font-size: 0.5rem;
    line-height: 0.5rem;
    @include sizes('1.5rem');
  }

  &.middle {
    font-size: 0.75rem;
    line-height: 0.75rem;
    @include sizes('2rem');
  }

  &.large {
    font-size: 1.375rem;
    line-height: 1.375rem;
    @include sizes('5rem');
  }

  &.semiLarge {
    @include body-s;
    @include sizes('3rem');
  }

  &.huge {
    font-size: 3.75rem;
    line-height: 3.75rem;
    @include sizes('12rem');
  }
}
