@import 'app/common/styles/text-colors.module.scss';

.footer {
  padding-block: 0.5rem;
  box-shadow: 0 -0.5rem 2rem #fff;
  margin-block-start: auto;
  width: 100%;

  .action {
    padding: 0.5rem 1rem;
  }
}
