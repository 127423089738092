@import 'app/common/styles/breakpoints.module.scss';

$orientation: (
        'top': 'block-start',
        'right': 'inline-end',
        'bottom': 'block-end',
        'left': 'inline-start'
);

@mixin orientationMap($type, $item, $size, $prefix: '') {
  @each $variant, $orientation in $orientation {
    &.#{$prefix}#{$type}-#{$variant}-#{$item} {
      #{$type}-#{$orientation}: $size;
    }
  }
}

@mixin orientationXMap($type, $item, $size, $prefix: '') {
  &.#{$prefix}#{$type}-x-#{$item} {
    #{$type}-inline: $size;
  }
}

@mixin orientationYMap($type, $item, $size, $prefix: '') {
  &.#{$prefix}#{$type}-y-#{$item} {
    #{$type}-block: $size;
  }
}

@mixin orientationAllMap($type, $item, $size, $prefix: '') {
  &.#{$prefix}#{$type}-all-#{$item} {
    #{$type}: $size;
  }
}

@mixin paddingXMap($prefix: '') {
  @for $i from 0 through 32 {
    $size: $i * 0.125rem;
    @include orientationXMap('padding', $i, $size, $prefix);
  }
}

@mixin marginXMap($prefix: '') {
  @for $i from 0 through 32 {
    $size: $i * 0.125rem;
    @include orientationXMap('margin', $i, $size, $prefix);
  }
}

@mixin paddingYMap($prefix: '') {
  @for $i from 0 through 32 {
    $size: $i * 0.125rem;
    @include orientationYMap('padding', $i, $size, $prefix);
  }
}

@mixin marginYMap($prefix: '') {
  @for $i from 0 through 32 {
    $size: $i * 0.125rem;
    @include orientationYMap('margin', $i, $size, $prefix);
  }
}

@mixin paddingAllMap($prefix: '') {
  @for $i from 0 through 32 {
    $size: $i * 0.125rem;
    @include orientationAllMap('padding', $i, $size, $prefix);
  }
}

@mixin marginAllMap($prefix: '') {
  @for $i from 0 through 32 {
    $size: $i * 0.125rem;
    @include orientationAllMap('margin', $i, $size, $prefix);
  }
}

@mixin paddingMap($prefix: '') {
  @for $i from 0 through 32 {
    $size: $i * 0.125rem;
    @include orientationMap('padding', $i, $size, $prefix);
  }
}

@mixin marginMap($prefix: '') {
  @for $i from 0 through 32 {
    $size: $i * 0.125rem;
    @include orientationMap('margin', $i, $size, $prefix);
  }
}

@mixin marginAutoMap($prefix: '') {
  @each $variant, $orientation in $orientation {
    &.#{$prefix}margin-auto-#{$variant} {
      margin-#{$orientation}: auto;
    }
  }
}

@mixin gapMap($prefix: '') {
  @for $i from 0 through 32 {
    $size: $i * 0.125rem;
    &.#{$prefix}gap-#{$i} {
      gap: $size;
    }
  }
}

@mixin sizeMap($prefix: '') {
  @for $i from 1 through 12 {
    $width: calc((100% * $i) / 12);
    &.#{$prefix}size-#{$i} {
      flex-grow: 0;
      max-width: $width;
      flex-basis: $width;
    }
  }
}

$direction: 'row', 'row-reverse', 'column', 'column-reverse';

@mixin directionMap($prefix: '') {
  @each $variant in $direction {
    &.#{$prefix}direction-#{$variant} {
      flex-direction: #{$variant};
    }
  }
}

$wrap: 'nowrap', 'wrap', 'wrap-reverse';

@mixin wrapMap($prefix: '') {
  @each $variant in $wrap {
    &.#{$prefix}wrap-#{$variant} {
      flex-wrap: #{$variant};
    }
  }
}

$justify: 'flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'space-evenly';

@mixin justifyMap($prefix: '') {
  @each $variant in $justify {
    &.#{$prefix}justify-#{$variant} {
      justify-content: #{$variant};
    }
  }
}

$alignType: 'content', 'items', 'self';
$align: 'stretch', 'center', 'flex-start', 'flex-end', 'space-between', 'space-around', 'baseline';

@mixin alignMap($prefix: '') {
  @each $type in $alignType {
    @each $variant in $align {
      &.#{$prefix}align-#{$type}-#{$variant} {
        align-#{$type}: #{$variant};
      }
    }
  }
}

$position: 'absolute', 'relative', 'fixed', 'sticky';

@mixin positionMap($prefix: '') {
  @each $variant in $position {
    &.#{$prefix}position-#{$variant} {
      position: #{$variant};
    }
  }
}

$display: 'inline-flex', 'flex', 'inline-block', 'block', 'inline';

@mixin displayMap($prefix: '') {
  @each $variant in $display {
    &.#{$prefix}display-#{$variant} {
      display: #{$variant};
    }
  }
}

@mixin allStylesMap($prefix: '') {
  @include displayMap($prefix);
  @include positionMap($prefix);
  @include sizeMap($prefix);
  @include paddingXMap($prefix);
  @include marginXMap($prefix);
  @include paddingYMap($prefix);
  @include marginYMap($prefix);
  @include paddingAllMap($prefix);
  @include marginAllMap($prefix);
  @include paddingMap($prefix);
  @include marginMap($prefix);
  @include marginAutoMap($prefix);
  @include directionMap($prefix);
  @include wrapMap($prefix);
  @include justifyMap($prefix);
  @include alignMap($prefix);
  @include gapMap($prefix);
}

.box {
  display: flex;
  @include allStylesMap();

  &.hidden {
    display: none;
  }

  @include sm-breakpoint {
    @include allStylesMap('sm-');
  }

  @include md-breakpoint {
    @include allStylesMap('md-');
  }

  @include lg-breakpoint {
    @include allStylesMap('lg-');
  }

  @include xl-breakpoint {
    @include allStylesMap('xl-');
  }
}
