@import 'app/common/styles/button-colors.module.scss';
@import 'app/common/styles/icon-colors.module.scss';
@import 'app/common/styles/text-sizes.module.scss';

.root {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  border: none;
  border-radius: 0.5rem;
  @include body-s;
  letter-spacing: $t-ls;
  width: fit-content;
  min-height: 3rem;
  min-width: 6.875rem;
  max-width: 100%;
  transition: all 0.3s;

  &.fullWidth {
    width: 100%;
  }

  &:focus-visible {
    outline: none;
  }

  svg {
    transition: all 0.3s;
  }
}

.content {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
}

.primary {
  color: $button-primary-text;
  border: 0.0625rem solid $button-primary-fill-border-default;
  background-color: $button-primary-fill-border-default;
  transition: background-color 0.3s;

  &:hover,
  &:focus {
    background-color: $button-primary-fill-border-hover;
    border-color: $button-primary-fill-border-hover;
  }

  &:active,
  &.active {
    background-color: $button-primary-fill-border-active;
    border-color: $button-primary-fill-border-active;
  }

  &.disabled {
    background-color: $button-primary-fill-border-disabled;
    border-color: $button-primary-fill-border-disabled;
  }

  .loader {
    fill: $icon-primary-inverse !important;
  }
}

.outlined {
  background-color: $button-outline-fill;
  border: 0.0625rem solid $button-outline-border-text-default;
  color: $button-outline-border-text-default;

  &:hover,
  &:focus {
    border-color: $button-outline-border-text-hover;
    color: $button-outline-border-text-hover;

    svg {
      fill: $button-outline-border-text-hover;
    }
  }

  &:active,
  &.active {
    border-color: $button-outline-border-text-active;
    color: $button-outline-border-text-active;

    svg {
      fill: $button-outline-border-text-active;
    }
  }

  &.disabled {
    border-color: $button-outline-border-text-disabled;
    color: $button-outline-border-text-disabled;

    svg {
      fill: $button-outline-border-text-disabled;
    }

    svg.loader {
      fill: $icon-primary;
    }
  }
}

.secondary {
  background-color: $button-outline-fill;
  border: 0.0625rem solid $button-outline-fill;
  color: $button-inverse-text-default;

  &:hover,
  &:focus {
    border-color: $button-outline-border-text-hover;
    color: $button-outline-border-text-hover;

    svg {
      fill: $button-outline-border-text-hover;
    }
  }

  &:active,
  &.active {
    border-color: $button-outline-border-text-active;
    color: $button-outline-border-text-active;

    svg {
      fill: $button-outline-border-text-active;
    }
  }

  &.disabled {
    border-color: $button-outline-border-text-disabled;
    color: $button-outline-border-text-disabled;

    svg {
      fill: $button-outline-border-text-disabled;
    }

    svg.loader {
      fill: $icon-primary;
    }
  }
}

.shadow {
  background-color: $button-inverse-fill;
  border: 0.0625rem solid $button-inverse-fill;
  color: $button-inverse-text-default;

  &:hover,
  &:focus {
    color: $button-inverse-text-hover;
    box-shadow: $button-inverse-shadow-hover;
  }

  &:active,
  &.active {
    color: $button-inverse-fill-text-active;
    box-shadow: $button-inverse-fill-shadow-active;
  }

  &.disabled {
    color: $button-inverse-fill-text-disabled;
    box-shadow: none;

    .loader {
      fill: $icon-primary;
    }
  }
}

.text {
  min-height: auto;
  min-width: auto;
  padding: 0;
  border-radius: 0;
  background-color: transparent;
  color: $button-outline-border-text-default;

  .content {
    padding: 0;

    &.paddingLeft {
      padding-inline-start: 0.5rem;
    }

    &.paddingRight {
      padding-inline-end: 0.5rem;
    }
  }

  &:hover,
  &:focus {
    color: $button-outline-border-text-hover;

    svg {
      fill: $button-outline-border-text-hover;
    }
  }

  &:active,
  &.active {
    color: $button-outline-border-text-active;

    svg {
      fill: $button-outline-border-text-active;
    }
  }

  &.disabled {
    color: $button-outline-border-text-disabled;

    svg {
      fill: $button-outline-border-text-disabled;
    }

    svg.loader {
      fill: $icon-primary;
    }
  }
}

.disabled {
  cursor: not-allowed !important;
}
