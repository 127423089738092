@import 'app/common/styles/status-colors.module.scss';

.filesListWrapper {
  padding-block-start: 0.625rem;

  .listItem,
  .text {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    word-break: break-word;

    .icon,
    .removeIcon {
      flex-shrink: 0;
    }

    .icon {
      margin-inline-end: 0.5rem;
    }

    .removeIcon {
      margin-inline-start: 0.5rem;
      cursor: pointer;
      transition: fill 0.3s;

      &:hover {
        fill: $status-danger;
      }
    }

    &:not(:last-of-type) {
      padding-inline-end: 0.25rem;
    }
  }
}
