@import 'app/common/styles/background-colors.module.scss';
@import 'app/common/styles/status-colors.module.scss';
@import 'app/common/styles/text-colors.module.scss';

.input {
  height: 100%;
  width: 100%;
  background-color: transparent;
  border-radius: 0.5rem;
  border: 0.0625rem solid $bg-border;
  padding: 0 1rem;
  position: relative;
  color: inherit;
  font-size: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: inherit;
  outline: none !important;
  -webkit-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:disabled {
    background-color: transparent;
    border: 0.0625rem solid $bg-border;
  }

  &::-webkit-input-placeholder {
    color: $text-teritary;
  }

  &:-moz-placeholder {
    color: $text-teritary;
  }

  &:-internal-autofill-selected {
    color: $text-primary !important;
    background-color: transparent !important;
  }

  &:focus {
    border-color: $bg-accent;

    &-visible {
      outline-offset: 0;
    }
  }

  &.error {
    border-color: $status-danger;
    color: $status-danger;

    &::-webkit-input-placeholder {
      color: $status-danger;
    }

    &:-moz-placeholder {
      color: $status-danger;
    }
  }

  &.deactivated {
    caret-color: transparent;
    cursor: pointer;
  }

  &Wrapper {
    height: 3rem;
    position: relative;
    background-color: $bg-island;
    font-size: 0.875rem;
    line-height: 1rem;
    border-radius: 0.5rem;

    .labels {
      display: inline-block;
      position: absolute;
      font-size: inherit;
      line-height: inherit;
      max-width: 100%;
      inset-inline-start: 0;
      inset-block-start: 50%;
      transform: translateY(-50%);
      padding: 0 1.125rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: text;
    }

    .prefix,
    .postfix,
    .clearButton {
      display: flex;
      align-items: center;
      position: absolute;
      inset-block-start: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }

    .prefix {
      inset-inline-start: 1rem;
    }

    .postfix {
      inset-inline-end: 1rem;

      &.button {
        cursor: pointer;
      }
    }

    .clear {
      &Button {
        inset-inline-end: 1rem;
        cursor: pointer;
      }

      &Icon {
        opacity: 0.5;
        transition: opacity 0.3s;

        &:hover {
          opacity: 1;
        }
      }
    }

    &.withPrefix {
      input {
        padding-inline-start: 2.5rem;
      }

      .labels {
        padding-inline-start: 2.625rem;;
      }
    }

    &.withPostfix {
      input {
        padding-inline-end: 3rem;
      }

      .labels {
        padding-inline-end: 3rem;
      }

      .clearButton {
        inset-inline-end: 3rem;
      }
    }

    &.cleanable {
      input {
        padding-inline-end: 3rem;
      }

      .labels {
        padding-inline-end: 3rem;
      }

      &.withPostfix {
        input {
          padding-inline-end: 4.5rem;
        }

        .labels {
          padding-inline-end: 4.5rem;
        }
      }
    }

    &.disabled {
      background-color: $bg-island-inner;
      cursor: not-allowed;

      & > * {
        pointer-events: none;
      }
    }
  }
}
