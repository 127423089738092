@import 'app/common/styles/background-colors.module.scss';
@import 'app/common/styles/text-colors.module.scss';
@import 'app/common/styles/status-colors.module.scss';

.tooltip {
  position: fixed;
  display: inline-block;
  background-color: $bg-accent;
  padding: 0.125rem 0.375rem;
  border-radius: 0.25rem;
  width: fit-content;
  max-width: 24rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $text-primary-inverse;
  white-space: pre-line;

  .indicator {
    position: fixed;
    width: 0.5rem;
    height: 0.5rem;
    background-color: inherit;
  }

  &.success {
    background-color: $status-success;
  }

  &.warning {
    background-color: $status-warning;
  }

  &.error {
    background-color: $status-danger;
  }

  &.top {
    .indicator {
      transform: translateY(-60%) rotate(45deg);
    }
  }

  &.bottom {
    .indicator {
      transform: translateY(-40%) rotate(45deg);
    }
  }

  &.left,
  &.right {
    .indicator {
      position: absolute;
      inset-block-start: 50%;
    }
  }

  &.left {
    .indicator {
      inset-inline-end: 0;
      transform: translate(50%, -50%) rotate(45deg);
    }
  }

  &.right {
    .indicator {
      inset-inline-start: 0;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}
