$bg-globe: var(--background-globe);
$bg-island: var(--background-island);
$bg-island-inner: var(--background-island-inner);
$bg-island-shadow: var(--background-island-shadow);
$bg-accent: var(--background-accent);
$bg-border: var(--background-border);
$bg-modal: var(--background-modal);
$bg-warning-light: rgba(var(--status-warning-rgb), 0.1);
$bg-erorr-light: rgba(var(--status-danger-rgb), 0.1);
$bg-success-light: rgba(var(--status-success-rgb), 0.1);
$bg-accent-light: rgba(var(--background-accent-rgb), 0.1);
