@import 'app/common/styles/text-colors.module.scss';

.menuButton {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  color: $text-primary;
  user-select: none;
  cursor: pointer;

  .icon {
    fill: $text-primary;
    margin-block-end: 0.25rem;
    transition: fill 0.3s;
  }

  &.active,
  &:hover {
    color: $text-link;

    .icon {
      fill: $text-link;
    }
  }

  &:focus {
    text-shadow: none;
  }
}
