@import 'app/common/styles/background-colors.module.scss';
@import 'app/common/styles/text-colors.module.scss';

.menuSectionLink {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  overflow: hidden;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $text-primary;
  transition: color 0.3s;
  cursor: pointer;

  &:last-of-type {
    margin-block-end: 0.75rem;
  }

  .icon {
    flex-grow: 0;
    flex-shrink: 0;
    margin-inline-end: 0.5rem;
    fill: $text-primary;
    transition: fill 0.3s;
  }

  &.active,
  &:hover {
    color: $text-link;

    .icon {
      fill: $text-link;
    }
  }

  &.active {
    background-color: $bg-island-inner;

    &::before {
      content: '';
      position: absolute;
      inset-block-start: 0;
      inset-inline-start: 0;
      width: 0.25rem;
      height: 100%;
      background-color: $bg-accent;
      border-radius: 0 0.25rem 0.25rem 0;
    }
  }

  &:focus {
    text-shadow: none;
  }
}
