@import 'app/common/styles/background-colors.module.scss';

.header {
  &Wrapper {
    box-shadow: 0 0 -1rem 0 $bg-island;
    z-index: 1;
  }
}

.navigationContainer {
  max-height: 100%;
  flex-basis: 100%;
  padding: 1rem;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.footer {
  &Wrapper {
    box-shadow: 0 0 1rem 0 $bg-island;
    z-index: 1;
  }
  &Action {
    line-height: 1.5rem;
  }
}
