.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;

  .header {
    margin: 2rem 0;
  }

  .content {
    width: 100%;
    min-height: calc(100vh - 7rem);
    padding-block-end: 3rem;
  }
}
