.toggleOpenSectionButton {
  cursor: pointer;

  .icon {
    margin-inline-start: auto;
    transition: transform 0.3s;

    &.active {
      transform: rotate(90deg);
      transition: transform 0.3s;
    }
  }
}

.navSection {
  max-height: 0;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;

  &.open {
    max-height: 33.75rem;
    opacity: 1;
    transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.menuSectionWrapper {
  min-height: 3.5rem;
  border-radius: 0.5rem;

  span,
  a {
    line-height: 1.5rem !important;
  }
}
