@import 'app/common/styles/background-colors.module.scss';

.popup {
  position: fixed;
  background-color: $bg-island;
  box-shadow: $bg-island-shadow;
  border-radius: 0.75rem;
  padding: 0.5rem;
  z-index: 100;
}
