@import 'app/common/styles/background-colors.module.scss';
@import 'app/common/styles/text-colors.module.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 5rem 0;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
}

.row {
  display: flex;
  width: 67.125rem;
  height: 25rem;
}

.col {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  &:first-child {
    flex: 0 0 25rem;
    padding-inline-end: 6rem;
    border-right: 0.188rem solid $bg-accent;
  }

  &:last-child {
    padding-inline-start: 6rem;
  }
}

.title {
  margin-block-end: 2rem;
}

.text {
  margin-block-end: 1rem;
  width: 36.625rem;

  &:last-of-type {
    width: 21.875rem;
  }
}

.number {
  font-weight: 700;
  font-size: 9rem;
  line-height: 10.875rem;
  color: $text-link;
}

.button {
  margin-block-start: 1rem;
}

[dir='rtl'] .col {
  &:first-child {
    border-right: unset;
    border-left: 0.188rem solid $bg-accent;
  }
}
