@import 'app/common/styles/background-colors.module.scss';
@import 'app/common/styles/status-colors.module.scss';

.dropzone {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 1.75rem 1.5rem;
  border: 0.0625rem dashed $bg-accent;
  border-radius: 0.5rem;
  overflow: hidden;

  &.error {
    border: 0.0625rem dashed $status-danger;
  }

  .dragMessage {
    position: absolute;
    inset-inline-start: 0;
    inset-block-start: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon {
    margin-inline-end: 0.625rem;
    flex-shrink: 0;
  }

  input:disabled {
    cursor: not-allowed;
  }

  .activator {
    opacity: 0;
    position: absolute;
    inset-inline-start: 0;
    inset-block-start: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    & > * {
      display: none;
    }
  }
}
