@import 'app/common/styles/text-colors.module.scss';

.controlButton {
  display: flex;
  align-items: center;
  color: $text-primary;
  font-size: 0.875rem;
  line-height: 1.25rem;
  transition: color 0.3s;
  cursor: pointer;

  .icon {
    margin-inline-end: 0.5rem;
    fill: $text-primary;
    transition: fill 0.3s;

    &.reverse {
      margin-inline-end: 0;
      margin-inline-start: 0.5rem;
    }
  }

  &:hover {
    color: $text-link;

    .icon {
      fill: $text-link;
    }
  }
}
