@import 'app/common/layouts/main-layout/variables';

.header {
  .togglePinButton {
    margin-inline-start: auto;
    display: none;
    cursor: pointer;
  }

  .link {
    display: flex;
  }
}

@media screen and (max-width: $mediaMaxWidth) {
  .header {
    .togglePinButton {
      display: inline-block;
    }
  }
}
