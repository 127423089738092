@import 'app/common/styles/background-colors.module.scss';

.paper {
  display: block;
  background-color: $bg-island;
  box-shadow: $bg-island-shadow;
  border-radius: 1rem;
  &.overflowHidden {
    overflow: hidden;
  }
}
