.root {
  position: relative;
  width: 100%;
  min-height: 6.875rem;
}

.loader {
  animation: rotating 1s linear infinite;
}

.listLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10rem;
}

.loaderWrapper {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 2;
}

.pageLoaderWrapper {
  position: absolute;
  inset-block-start: calc(50% + 3rem);
  left: 50%;
  transform: translate(-50%, -50%);
}

.title {
  padding-block-start: 1rem;
}

.loaderCover {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 3;

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    min-height: 10rem;
    max-height: 35rem;
    position: sticky;
    inset-block-start: calc(50vh - 17.5rem);
    inset-block-end: 2rem;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
