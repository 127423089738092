@import 'app/common/styles/background-colors.module.scss';

.footer {
  position: fixed;
  inset-inline-start: 0;
  inset-block-end: 0;
  width: 100vw;
  min-width: 22.5rem;
  height: 3.5rem;
  padding-block: 0;
  padding-inline: 1rem 1.25rem;
  background-color: $bg-island;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
  z-index: 110;
}
