@import 'app/common/styles/background-colors.module.scss';
@import 'app/common/styles/text-colors.module.scss';
@import 'app/common/styles/text-sizes.module.scss';

.tabsController {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-bottom: 0.0625rem solid $bg-border;
  font-weight: $t-weight-bold;
  color: $text-teritary;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .carousel {
    .arrowLeft,
    .arrowRight {
      position: absolute;
      inset-block-start: 0.3rem;
      cursor: pointer;

      &Wrapper {
        position: absolute;
        width: 2rem;
        height: 1.75rem;
        z-index: 2;
      }
    }

    .arrowLeft {
      inset-inline-start: 0;

      &Wrapper {
        inset-inline-start: -1px;
        background: linear-gradient(90deg, #fff 40%, hsla(0, 0%, 100%, 0));
      }
    }

    .arrowRight {
      inset-inline-end: 0;

      &Wrapper {
        inset-inline-end: -1px;
        background: linear-gradient(270deg, #fff 40%, hsla(0, 0%, 100%, 0));
      }
    }
  }
}

.tab {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  padding-block-end: 0.625rem;
  transition: color 0.3s;
  user-select: none;
  cursor: pointer;

  &:not(:last-of-type) {
    margin-inline-end: 1.5rem;
  }

  &:hover {
    color: $text-secondary;
  }

  &.disabled {
    color: inherit;
    cursor: default;
  }

  &.noWrap {
    white-space: nowrap;
  }

  &::after {
    position: absolute;
    content: '';
    inset-block-end: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 0.1875rem;
    background-color: transparent;
    transition: background-color 0.3s;
  }

  &.active {
    color: $text-primary;

    &::after {
      background-color: $bg-accent;
    }
  }
}
