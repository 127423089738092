@import 'app/common/styles/status-colors.module.scss';

.notificationIcon {
  position: absolute;
  inset-block-start: -0.4375rem;
  inset-inline-end: -0.4375rem;
  fill: none !important;
  stroke: $status-danger;
  stroke-opacity: 0.3;

  circle {
    fill: $status-danger !important;
  }
}
