@import 'app/common/styles/breakpoints.module.scss';
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-block-start: 0.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin !important;
  
  --drawer-scrollbar-width: calc(100vw - 100%);
}

@include lg-breakpoint {
  .wrapper {
    --drawer-scrollbar-width: calc(20rem - 100%);
  }
}
