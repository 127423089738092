.container {
  flex: 1 1 0;
  .logo {
    width: 7.438rem;
    height: 2.011rem;

    &Wrapper {
      height: 3.25rem;
      max-width: 15rem;
      margin: 2rem 0;
    }
    object-fit: contain;
  }
}
